import { db } from '@/db'

const firebaseCollection = 'user-coupons'
const firebaseSubCollection = 'coupons'

const insertCoupon2Firebase = (coupon) => {
  Object.keys(coupon).forEach(key => {
    if (coupon[key] === undefined) {
      delete coupon[key];
    }
  });
  db.collection(firebaseCollection)
    .doc(coupon.userId)
    .collection(firebaseSubCollection)
    .doc(coupon.id)
    .set(coupon)
}
const updateCoupon2Firebase = (coupon) => {
  db.collection(firebaseCollection)
    .doc(coupon.userId)
    .collection(firebaseSubCollection)
    .doc(coupon.id)
    .update({
      ...coupon
    })
    .then(() => {
      console.log('Coupon type successfully updated!');
    })
    .catch((error) => {
      console.error('Error updating coupon type: ', error);
    });
}
const fetchCouponsFromFirebase = ({
  userId
}) => {
  return new Promise((resolve) => {
    db.collection(firebaseCollection)
    .doc(userId)
    .collection(firebaseSubCollection)
    .get()
    .then((querySnapshot) => {
      resolve(querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data()
        }
      }))
    })
    .catch((error) => {
      console.error('Error getting transactions: ', error);
      resolve([])
    });
  })
}
const fetchCouponFromFirebase = ({
  userId,
  couponId
}) => {
  return new Promise((resolve, reject) => {
    db.collection(firebaseCollection)
    .doc(userId)
    .collection(firebaseSubCollection)
    .doc(couponId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        resolve({
          id: doc.id,
          ...doc.data()
        })
      } else {
        reject(new Error('Coupon not found'))
      }
    })
    .catch((error) => {
      console.error('Error getting coupon: ', error);
      reject(new Error('Coupon not found'))
    });
  })
}

const defaultParkingCoupons = [
  {
    id: 'e4524074-c4f4-4698-97fa-c17cabbcc2c8',
    title: "10 元雲林路邊停車折抵券",
    company: "惠隆資訊",
    type: "parking",
    pay: 10,
  },
  {
    id: 'b1635819-f6ce-4e5a-abdd-797017696f2a',
    title: "20 元雲林路邊停車折抵券",
    company: "惠隆資訊",
    type: "parking",
    pay: 20,
  },
  {
    id: '1193920c-0d09-4873-9126-6f427373305c',
    title: "30 元雲林路邊停車折抵券",
    company: "惠隆資訊",
    type: "parking",
    pay: 30,
  },
]
// const getCouponFromLocalStorage = () => {
//   const coupons = localStorage.getItem('UserCoupons') || '[]'
//   return JSON.parse(coupons)
// }

const state = () => ({
  parkingCoupons: defaultParkingCoupons,
  userCoupons: [],
})

const getters = {
  parkingCoupons(state) {
    return state.parkingCoupons
  },
  userCoupons(state) {
    return state.userCoupons
  },
}

const mutations = {
  setUserCoupons(state, coupons) {
    state.userCoupons = coupons
    // localStorage.setItem('UserCoupons', JSON.stringify(coupons))
  }
}

const actions = {
  async fetchUserCoupons({ commit, rootState }) {
    // const coupons = getCouponFromLocalStorage()
    const coupons = await fetchCouponsFromFirebase({
      userId: rootState.user.userId
    })
    commit('setUserCoupons', coupons)
  },
  fetchUserCoupon({ rootState }, id) {
    return fetchCouponFromFirebase({
      userId: rootState.user.userId,
      couponId: id
    })
    // return new Promise((resolve, reject) => {
    //   // const coupons = getCouponFromLocalStorage()
    //   // const item = coupons.find(coupon => coupon.id === id)
    //   if (!item) {
    //     return reject(new Error('Coupon not found'))
    //   }
    //   return resolve(item)
    // })
  },
  addUserCoupon({ commit, state }, coupon) {
    const isExist = state.userCoupons.some(c => c.id === coupon.id)
    if (isExist) return

    const coupons = [...state.userCoupons, coupon]
    insertCoupon2Firebase(coupon)
    commit('setUserCoupons', coupons)
  },
  updateUserCoupon({ commit, state }, coupon) {
    const coupons = state.userCoupons.map(c => {
      if (c.id === coupon.id) {
        return coupon
      }
      return c
    })

    updateCoupon2Firebase(coupon)
    commit('setUserCoupons', coupons)
  },
  removeUserCoupon({ commit, state }, coupon) {
    const coupons = state.userCoupons.filter(c => c.id !== coupon.id)
    commit('setUserCoupons', coupons)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};